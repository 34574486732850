import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './echoes.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Range, getTrackBackground } from 'react-range';
import { WWEchoes } from '../../modules/ww/data/ww-echoes';
import { WWEchoEntry } from '../../modules/ww/echoes/ww-echo';
import { Form } from 'react-bootstrap';
import lodash from 'lodash';

const WWEchoesPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const [currentLevel, setCurrentLevel] = useState([2]);
  const [currentSet, setCurrentSet] = useState(0);
  const [visibleEchoes, setVisibleEchoes] = useState(WWEchoes);
  const [textSearch, setTextSearch] = useState('');

  const echoSetChangeHandler = (e: number) => {
    setCurrentSet(e);
  };

  useEffect(() => {
    let filtered = WWEchoes;

    filtered = filtered.sort((a, b) => {
      let retval = 0;
      if (a.Rarity > b.Rarity) retval = -1;
      if (a.Rarity < b.Rarity) retval = 1;
      if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
      return retval;
    });

    setVisibleEchoes(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = WWEchoes;

    if (currentSet != 0) {
      filtered = filtered
        .sort((a, b) => {
          let retval = 0;
          if (a.Rarity > b.Rarity) retval = -1;
          if (a.Rarity < b.Rarity) retval = 1;
          if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
          return retval;
        })
        .filter((echo) => echo.Sonata_Group.indexOf(currentSet) > -1);
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (echo) =>
          echo.Name &&
          echo.Name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'ID');
    }

    setVisibleEchoes(filtered);
    forceUpdate();
  }, [currentSet, textSearch]);

  const items = visibleEchoes.map((weapon, idx) => {
    return (
      <div key={idx}>
        <WWEchoEntry name={weapon.Name} level={currentLevel} mode="icon" />
      </div>
    );
  });

  return (
    <DashboardLayout className={'generic-page ww-echoes-page'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_echo2.png"
            alt="Echoes"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Echoes</h1>
          <h2>List of all Echoes available in Wuthering Waves.</h2>
          <p>
            Last updated: <strong>29/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Echoes"
        subtitle={
          <span className="additional-text">
            Showing {visibleEchoes.length} echo
            {visibleEchoes.length !== 1 && 'es'}
          </span>
        }
      />
      <div className="stats-header">
        <div className="info">
          <p>
            Please select the <strong>Rank</strong> of the echo (2-5).
          </p>
        </div>
        <div className="level-changer">
          <span className="current-level">
            Lv. <span className={`level`}>{currentLevel}</span>
          </span>
          <div className={`level-slider `}>
            <Range
              step={1}
              min={2}
              max={5}
              values={currentLevel}
              onChange={(values) => setCurrentLevel(values)}
              renderTrack={({ props, children }) => (
                <div
                  role="button"
                  tabIndex={0}
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '0px',
                      background: getTrackBackground({
                        values: currentLevel,
                        colors: ['#FFD780', '#484950'],
                        min: 2,
                        max: 5
                      }),
                      alignSelf: 'center'
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: '0px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: '14px',
                      width: '5px',
                      backgroundColor: isDragged ? '#FFD780' : '#484950'
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Form.Control
        size="lg"
        type="text"
        placeholder="Search for Echo"
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
      />
      <div className="echoes-filter">
        <div
          className={`option all ${currentSet === 0 && 'selected'}`}
          onClick={() => echoSetChangeHandler(0)}
        >
          <h6>All echo sets</h6>
        </div>
        <div
          className={`option Freezing ${currentSet === 1 && 'selected'}`}
          onClick={() => echoSetChangeHandler(1)}
        >
          <StaticImage src="../../images/ww/icons/set_1.png" alt="Set" />
          <h6>Freezing Frost</h6>
        </div>
        <div
          className={`option Molten ${currentSet === 2 && 'selected'}`}
          onClick={() => echoSetChangeHandler(2)}
        >
          <StaticImage src="../../images/ww/icons/set_2.png" alt="Set" />
          <h6>Molten Rift</h6>
        </div>
        <div
          className={`option Void ${currentSet === 3 && 'selected'}`}
          onClick={() => echoSetChangeHandler(3)}
        >
          <StaticImage src="../../images/ww/icons/set_3.png" alt="Set" />
          <h6>Void Thunder</h6>
        </div>
        <div
          className={`option Sierra ${currentSet === 4 && 'selected'}`}
          onClick={() => echoSetChangeHandler(4)}
        >
          <StaticImage src="../../images/ww/icons/set_4.png" alt="Set" />
          <h6>Sierra Gale</h6>
        </div>
        <div
          className={`option Celestial ${currentSet === 5 && 'selected'}`}
          onClick={() => echoSetChangeHandler(5)}
        >
          <StaticImage src="../../images/ww/icons/set_5.png" alt="Set" />
          <h6>Celestial Light</h6>
        </div>
        <div
          className={`option Havoc ${currentSet === 6 && 'selected'}`}
          onClick={() => echoSetChangeHandler(6)}
        >
          <StaticImage src="../../images/ww/icons/set_6.png" alt="Set" />
          <h6>Sun-sinking Eclipse</h6>
        </div>
        <div
          className={`option Rejuvenating ${currentSet === 7 && 'selected'}`}
          onClick={() => echoSetChangeHandler(7)}
        >
          <StaticImage src="../../images/ww/icons/set_7.png" alt="Set" />
          <h6>Rejuvenating Glow</h6>
        </div>
        <div
          className={`option Moonlit ${currentSet === 8 && 'selected'}`}
          onClick={() => echoSetChangeHandler(8)}
        >
          <StaticImage src="../../images/ww/icons/set_8.png" alt="Set" />
          <h6>Moonlit Clouds</h6>
        </div>
        <div
          className={`option Endless ${currentSet === 9 && 'selected'}`}
          onClick={() => echoSetChangeHandler(9)}
        >
          <StaticImage src="../../images/ww/icons/set_9.png" alt="Set" />
          <h6>Lingering Tunes</h6>
        </div>
      </div>
      <div className="set-details">
        {currentSet === 0 && (
          <p>Select a set to reveal its 2 and 5 piece bonuses.</p>
        )}
        {currentSet === 1 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Glacio">Glacio</strong> DMG
              increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon using Basic Attack or Heavy Attack,{' '}
              <strong className="Glacio">Glacio DMG</strong> increases by{' '}
              <strong>10%</strong>, stacking up to three times, lasting for
              <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 2 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Fusion">Fusion</strong>{' '}
              DMG% increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon using Resonance Skill,{' '}
              <strong className="Fusion">Fusion DMG</strong> increases by{' '}
              <strong>30%</strong> for <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 3 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Electro">Electro</strong>{' '}
              DMG increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon using Heavy Attack or Resonance Skill,{' '}
              <strong className="Electro">Electro DMG</strong> increases by{' '}
              <strong>15%</strong>, stacking up to <strong>2</strong> times,
              each stack lasting for <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 4 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Aero">Aero</strong> DMG
              increase by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon using Intro Skill,{' '}
              <strong className="Aero">Aero DMG</strong> increases by{' '}
              <strong>30%</strong> for <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 5 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Spectro">Spectro</strong>{' '}
              DMG increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon using Intro Skill,{' '}
              <strong className="Spectro">Spectro DMG</strong> increases by{' '}
              <strong>30%</strong> for <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 6 && (
          <>
            <p>
              <span>2 Set:</span> <strong className="Havoc">Havoc</strong> DMG
              increases by <strong>10%</strong>.
            </p>

            <p>
              <span>5 Set:</span> Upon using Basic Attack or Heavy Attack,{' '}
              <strong className="Havoc">Havoc DMG</strong> increases by{' '}
              <strong>7.5%</strong>, stacking up to four times for{' '}
              <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 7 && (
          <>
            <p>
              <span>2 Set:</span> Healing increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> Upon healing allies, increase ATK of the
              entire team by <strong>15%</strong>, lasting <strong>30s</strong>.
            </p>
          </>
        )}
        {currentSet === 8 && (
          <>
            <p>
              <span>2 Set:</span> Energy Regen increases by <strong>10%</strong>
              .
            </p>
            <p>
              <span>5 Set:</span> Upon using Outro Skill, ATK of the next
              Resonator increases by <strong>22.5%</strong> for{' '}
              <strong>15s</strong>.
            </p>
          </>
        )}
        {currentSet === 9 && (
          <>
            <p>
              <span>2 Set:</span> ATK increases by <strong>10%</strong>.
            </p>
            <p>
              <span>5 Set:</span> While on the field, ATK increases by{' '}
              <strong>5%</strong> every <strong>1.5s</strong>, stacking up to{' '}
              <strong>4</strong> times. Outro Skill DMG increases by{' '}
              <strong>60%</strong>.
            </p>
          </>
        )}
      </div>
      <div className="ww-weapons-container">{items}</div>
    </DashboardLayout>
  );
};

export default WWEchoesPage;

export const Head: React.FC = () => (
  <Seo
    title="Echoes | Wuthering Waves | Prydwen Institute"
    description="List of all Echoes available in Wuthering Waves"
    game="ww"
  />
);
